// import React from 'react'

import { useStaticQuery, graphql } from "gatsby"

import Layout from '../components/layout'
import SEO from '../components/seo'

import { MdSecurity, MdForum, MdBusiness } from 'react-icons/md'

/** @jsx jsx */
import { jsx } from 'theme-ui'

const ConfidentialityPage = () => {
    const data = useStaticQuery(pageQuery)
    const siteName = data.site.siteMetadata.siteTitle || "notre site"
    // const siteTagline = data.site.siteMetadata.siteTagline
    // const siteDesc = data.site.siteMetadata.siteDesc

    return (
        <Layout>
            <SEO title={`Politique de confidentialité ${siteName}`} />

            <h1 sx={{mt: 6}}>Politique de confidentialité</h1>
            <p>Vous trouverez sur cette page l'ensemble des réponses aux questions courantes concernant notre politique concernant la sécurité de vos données personnelles, en accord avec le Règlement Européen sur la Protection des Données (<a href="https://www.eugdpr.org/">RGPD</a>)</p>

            <h2>1. Qui sommes nous</h2>
            <ul>
                <li><MdBusiness sx={{mb: '-2px'}} /> Editeur du site: Lyketil Sàrl. Avenue de Riant Mont 5, 1004 Lausanne.</li>
                <li><MdSecurity sx={{mb: '-2px'}} /> Hébergeur: Netlify.com (GDPR, SOC II, HTTPS)</li>
                <li><MdForum sx={{mb: '-2px'}} /> Contact: hello@lyketil.com</li>
            </ul>

            <h2>2. Quelles données personnelles nous collectons, et pourquoi</h2>
            <h3>a. Formulaires de contact</h3>
            <p>Nous collections votre nom et prénom, email, entreprise, poste dans l'entreprise, numéro de téléphone ainsi que votre demande de prestation afin de pouvoir communiquer avec vous et mieux répondre à vos besoins. Pour conclure un contrat par exemple, nous sommes obligés de recueillir et traiter des données personnelles</p>
            <h3>b. Analytics</h3>
            <p>Nous analysons l'utilisation de notre site internet, en recueillant des données de navigation. Ces informations nous permettent d'améliorer notre site internet, nos offres, et le ciblage de nos publicités.</p>

            {/*
                <h3>c. Cookies</h3>
                <p><script id="CookieDeclaration" src="https://consent.cookiebot.com/3b0546cb-6274-4df2-88c4-69cbd793ead6/cd.js" type="text/javascript" async=""></script></p>
                <p>Refusez les cookies <a href="https://cookies.insites.com/disable-cookies/">depuis votre navigateur</a>.</p>
             */}

            <h2>3. Avec qui sont partagées les données personnelles</h2>
            <p>Nous collaborons uniquement avec des partenaires qui respectent les standards de sécurité maximum. Vos données sont uniquement partagées lorsque nous utilisons des logiciels d'autres parties, pour mieux répondre à vos besoins (CRM), améliorer notre site (analytics) et nos publicités (publicité en ligne).</p>

            <h2>4. Combien de temps nous conservons les données personnelles</h2>
            <p>Nous conservons les données personnelles 3 ans pour nos activités marketing et le suivi de notre clientèle. En ce qui concerne les données personnelles rattachées à la facturation, nous les gardons 6 ans.</p>

            <h2>5. Vos droits sur vos données personnelles</h2>
            <p>Vous avez le contrôle de vos données personnelles. Vous pouvez à tout moment nous écrire à hello@lyketil.com pour demander la suppression, modification, ou le transfert de vos données personnelles et nous vous répondrons dans les plus brefs délais.</p>

            <h2>6. Comment vos données sont protégées</h2>
            <p>Toutes nos données sont encryptées (HTTPS/SSL) avant d'être envoyées aux outils marketing que nous utilisons.<br />Aucune de vos données personnelles ne sont stockées dans nos bases de données du site internet. Les données sont stockées dans nos logiciels marketing, tous choisis pour leur conformité aux législations concernant la sécurité des données personnelles, ainsi qu'à leur niveau de sécurité.</p>

            <p><em>Lyketil peut modifier cette politique si les cookies sont obsolètes ou si nous avons de nouveaux besoins. Cette page montre toujours la dernière version. Cette politique a été modifiée en Juillet 2020. En cas de question, veuillez nous contacter : hello@lyketil.com .</em></p>
        </Layout>
    )
}

export default ConfidentialityPage

const pageQuery = graphql`
  query confidentialityQuery {
    site {
      siteMetadata {
        siteTitle
        siteDesc
        siteTagline
      }
    }
  }
`